import { navigate } from '@reach/router'
import React from 'react'

import { ROUTES } from '../../components/layout/Navigation'
import { OnboardingStep, useMatchOnboardingState } from '../../content/onboarding/OnboardingStep'
import { Complete } from '../../components/elements/Complete'


const OnboardingStepClaim = () => {
  const { actions, state } = useMatchOnboardingState()

  const reset = () => {
    actions.startAgain({})
    navigate(ROUTES.onboarding.step1)
  }

  return (
    <OnboardingStep
      step={4}
      desktopTitle={"We've started work!"}
      imageUrl='https://images.unsplash.com/photo-1557801200-9a8d901ded2a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80'
    />
  )
}

export default OnboardingStepClaim
